body {
    font-family: Inconsolata,sans-serif;
    cursor: default;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track-piece  {
    background-color: #c3b5c7;
}
::-webkit-scrollbar-thumb:vertical {
    height: 4px;
    background-color: #440356;
}
::-webkit-scrollbar-thumb:horizontal {
    height: 4px;
    background-color: #440356;
}
